<template>
  <v-list-item>
    <v-list-item-content v-ripple @click="onClick">
      <v-list-item-subtitle>
        <span class="overline">{{ tagLabel }}</span>
        <span class="caption" v-if="notification.tag == 'route' || notification.tag == 'boulder'">
          | {{ gradeTextMin }} to {{ gradeTextMax }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-title>
        {{ notification.title }}
        <span v-if="!notification.processed_at" class="warning--text caption">
          | Scheduled for {{ moment(notification.process_at).fromNow() }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle class="caption">
        {{ notification.message }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="!notification.processed_at">
      <v-btn icon @click="edit">
        <v-icon>tl-edit</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-action v-if="!notification.processed_at">
      <v-btn icon :disabled="removing" :loading="removing" @click="remove">
        <v-icon>tl-delete</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-action v-if="notification.processed_at">
      <v-list-item-action-text>{{ notification.processed_at | formatDate('lll') }}</v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Notification from '@/models/Notification'
import Grade from '@/services/grades/Grade'

export default {
  props: {
    notification: { type: Notification, default: new Notification() },
  },
  data: () => ({
    removing: false,
  }),
  computed: {
    tagLabel() {
      return Notification.tags[this.notification.tag].label
    },
    gradeTextMin() {
      return new Grade(this.notification.grade_min, this.notification.tag).name
    },
    gradeTextMax() {
      return new Grade(this.notification.grade_max, this.notification.tag).name
    },
  },
  methods: {
    onClick() {
      this.notification.processed_at ? this.show() : this.edit()
    },
    show() {
      this.$store.dispatch('dialog/alert', {
        maxWidth: 500,
        title: this.notification.title,
        text: this.notification.message,
        ok: 'Close',
      })
    },
    edit() {
      this.$router.push({ name: 'gymComm.editNotification', params: { notificationId: this.notification.id } })
    },
    async remove() {
      let doRemove = await this.$store.dispatch('dialog/confirm', { title: 'Are you sure?' })
      if (!doRemove) return
      this.removing = true
      if (await this.notification.isProcessed()) {
        this.$store.dispatch('toast/error', 'This notification has already been send!')
        this.removing = false
        return
      } else {
        this.notification
          .$apiDestroy()
          .then(() => this.$store.dispatch('toast/success', 'Notification has been cancelled'))
          .finally(() => (this.removing = false))
      }
    },
  },
}
</script>
