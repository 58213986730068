<template>
  <v-dialog v-model="open" max-width="700px" persistent>
    <v-form v-model="valid">
      <v-card>
        <v-card-title>
          <span>{{ this.notificationId ? 'Edit' : 'Create' }} notification</span>
          <div class="flex-grow-1"></div>
          <v-btn icon @click="close"><v-icon>tl-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <p>Type</p>
          <v-radio-group :value="notification.tag" @change="setProp('tag', $event)">
            <v-radio value="news" label="News" color="primary" />
            <v-radio value="competitions" label="Competition news" color="primary" />
            <v-radio v-if="gym.nr_of_routes" value="route" label="Route updates" color="primary" />
            <v-radio v-if="gym.nr_of_boulders" value="boulder" label="Boulder updates" color="primary" />
          </v-radio-group>

          <div v-if="notification.tag == 'route' || notification.tag == 'boulder'">
            <span>Specify the grade-range your update is about:</span>
            <tl-grade-range-slider v-model="gradeRange" :type="notification.tag" class="pa-4" />
          </div>

          <v-text-field
            :value="notification.title"
            label="Title"
            :rules="textRules"
            required
            maxlength="30"
            counter
            @input="setProp('title', $event)"
          />
          <v-textarea
            :value="notification.message"
            label="Message"
            :rules="textRules"
            required
            rows="1"
            auto-grow
            @input="setProp('message', $event)"
          />
          <v-select v-if="setProcessAt" v-model="time" :items="times" label="When to send" />
          <div v-if="!setProcessAt" class="d-flex justify-space-between">
            <span class="text--primary">Scheduled to be send {{ moment(notification.process_at).fromNow() }}</span>
            <v-btn text @click="setProcessAt = true">Change</v-btn>
          </div>

          <div v-if="spamCount">
            <div>
              You have already send or scheduled {{ spamCount }} message{{ spamCount > 1 ? 's' : '' }} this week.
            </div>
            <v-checkbox
              v-model="spamNotify"
              :rules="[v => !!v || 'You must agree to continue']"
              label="I understand this might feel like spam to some users."
              color="primary"
              class="mt-0"
              required
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" :loading="saving" :disabled="!valid" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Notification from '@/models/Notification'
import tlGradeRangeSlider from '@/components/shared/tl-grade-sliders/tl-grade-range-slider'

export default {
  components: {
    tlGradeRangeSlider,
  },
  props: {
    notificationId: { type: Number, default: null },
  },
  data: () => ({
    notification: new Notification(),
    open: false,
    valid: false,
    textRules: [v => (v && v.length >= 6) || 'Title must be at least 6 characters'],
    spamNotify: false,
    setProcessAt: true,
    time: 0,
    times: [
      { value: 0, text: 'Now' },
      { value: 5, text: 'In 5 minutes' },
      { value: 30, text: 'In 30 minutes' },
      { value: 60, text: 'In an hour' },
      { value: 2 * 60, text: 'In 2 hours' },
      { value: 3 * 60, text: 'In 3 hours' },
      { value: 4 * 60, text: 'In 4 hours' },
      { value: 5 * 60, text: 'In 5 hours' },
      { value: 6 * 60, text: 'In 6 hours' },
      { value: 12 * 60, text: 'In 12 hours' },
      { value: 24 * 60, text: 'In 24 hours' },
    ],
    saving: false,
  }),
  created() {
    this.$nextTick(() => (this.open = true))
    this.findOrCreateNotification()
  },
  computed: {
    ...mapState(['user', 'gym']),
    gradeRange: {
      get() {
        return [this.notification.grade_min, this.notification.grade_max]
      },
      set(newVal) {
        this.setProp('grade_min', newVal[0])
        this.setProp('grade_max', newVal[1])
      },
    },
    spamCount() {
      const weekAgo = this.moment().subtract(7, 'days').valueOf() // eslint-disable-line
      return this.gym.notifications.filter(n => {
        return n.process_at.getTime() > weekAgo && n.id != this.notificationId
      }).length
    },
  },
  methods: {
    setProp(prop, val) {
      this.notification.$update({ [prop]: val })
    },
    findOrCreateNotification() {
      if (this.notificationId) {
        this.notification = Notification.find(this.notificationId)
        this.setProcessAt = false
        if (!this.notification) return this.close()
      } else {
        this.notification = new Notification({
          user_id: this.user.id,
          gym_id: this.gym.id,
          tag: 'news',
          process_at: new Date(),
          grade_min: 2.0,
          grade_max: 10.0,
        })
      }
    },
    close() {
      this.notification.$restore()
      this.$router.replaceSmart({ name: 'gymComm' })
    },
    async save() {
      if (this.setProcessAt) {
        let process_at = this.moment().add(this.time || 0, 'minutes').toDate() // eslint-disable-line
        this.notification.$update({ process_at })
      }
      this.saving = true
      if (!this.notification.$isNew()) {
        if (await this.notification.isProcessed()) {
          this.$store.dispatch('toast/error', 'This notification has already been send!')
          return this.close()
        }
      }
      this.notification
        .$apiSave()
        .then(this.close)
        .finally(() => (this.saving = false))
    },
  },
}
</script>
