<template>
  <v-card :dark="appFeedback" :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>{{ appFeedback ? 'App feedback' : 'Feedback' }} from clients</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-btn icon @click="fetchFeedbacks">
        <v-icon>tl-autorenew</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="currentLabel" show-arrows>
          <v-tab v-for="label in labels" :key="label" :href="`#label-${label}`">
            {{ label }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="currentLabel">
      <v-tab-item v-for="label in labels" :key="label" :value="`label-${label}`">
        <v-card v-if="!itemsFiltered.length" flat>
          <v-card-text>Nothing to show here.</v-card-text>
        </v-card>
        <v-timeline v-else align-top dense>
          <tl-feedbacks-admin-item v-for="feedback in itemsPaginated" :key="feedback.id" :feedback="feedback" />
        </v-timeline>
        <tl-pagination-local :list="itemsFiltered" @list-paginated="itemsPaginated = $event" :items-per-page="10" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Gym from '@/models/Gym'
import Feedback from '@/models/Feedback'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'
import tlFeedbacksAdminItem from './tl-feedbacks-admin-item'

export default {
  components: {
    tlFeedbacksAdminItem,
    tlPaginationLocal,
  },
  props: {
    appFeedback: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    labels: ['inbox', 'todo', 'archived', 'removed'],
    currentLabel: 'label-inbox',
    itemsPaginated: [],
  }),
  computed: {
    ...mapState(['gym']),
    labelFilter() {
      return this.currentLabel.split('-').pop()
    },
    itemsFiltered() {
      let feedbacks = this.gym.feedbacks
      if (this.appFeedback) feedbacks = Feedback.all().filter(fb => !fb.gym_id)
      return feedbacks
        .filter(feedback => {
          if (this.labelFilter == 'inbox') return !feedback.label
          return feedback.label == this.labelFilter
        })
        .slice()
        .sort((a, b) => b.created_at - a.created_at)
    },
  },
  created() {
    this.fetchFeedbacks()
  },
  methods: {
    async fetchFeedbacks() {
      this.loading = true
      if (this.appFeedback) {
        await Feedback.$apiAll().finally(() => (this.loading = false))
      } else {
        let feedbacks = await Gym.$apiCall('feedbacks', { params: { gym_id: this.gym.id } }).finally(
          () => (this.loading = false)
        )
        Feedback.inject(feedbacks)
      }
    },
  },
}
</script>
