<template>
  <v-card :loading="loading">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Reported climbs</v-list-item-title>
        <v-list-item-subtitle>Climbs that require your attention</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click="fetchReported"><v-icon>tl-autorenew</v-icon></v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-card v-if="!reported.length" flat>
      <v-card-text>All is good, nothing to show here.</v-card-text>
    </v-card>

    <v-list two-line>
      <tl-reported-climb v-for="climb in reportedPaginated" :key="climb.id" :climb="climb" />
    </v-list>

    <tl-pagination-local :list="reported" @list-paginated="reportedPaginated = $event" :items-per-page="5" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Opinion from '@/models/Opinion'
import tlReportedClimb from './tl-reported-climb'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlReportedClimb,
    tlPaginationLocal,
  },
  data: () => ({
    loading: false,
    reportedPaginated: [],
  }),
  computed: {
    ...mapState(['gym']),
    reported() {
      return Opinion.all()
        .filter(o => !!o.date_reported && !o.report_removed && o.climb.gym_id == this.gym.id)
        .slice()
        .sort((a, b) => b.date_reported - a.date_reported)
        .reduce((climbs, o) => {
          if (!climbs.includes(o.climb)) climbs.push(o.climb)
          return climbs
        }, [])
    },
  },
  created() {
    this.fetchReported()
  },
  methods: {
    async fetchReported() {
      this.loading = true
      let params = {
        json_params: {
          filters: {
            date_reported: { null: false },
            date_report_removed: { null: true },
            climb: {
              deleted: false,
              gym_id: this.gym.id,
            },
          },
          includes: ['climb'],
        },
      }
      await Opinion.$apiAll({ params }).finally(() => (this.loading = false))
    },
  },
}
</script>
