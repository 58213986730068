<template>
  <tl-feedbacks-item :feedback="feedback">
    <template v-slot:action>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>tl-more-vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="reply">
            <v-list-item-title v-text="feedback.reply ? 'Edit reply' : 'Write reply'" />
          </v-list-item>
          <v-divider />
          <v-list-item v-for="(item, index) in menuItemsFiltered" :key="index" @click="setLabel(item.label)">
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </tl-feedbacks-item>
</template>

<script>
import { mapActions } from 'vuex'
import Feedback from '@/models/Feedback'
import tlFeedbacksItem from '@/components/extras/feedbacks/tl-feedbacks-item'

export default {
  components: {
    tlFeedbacksItem,
  },
  props: {
    feedback: { type: Feedback, default: () => {} },
  },
  data: () => ({
    menuItems: [
      { text: 'Mark unread', icon: 'tl-email', label: null },
      { text: 'Mark as todo', icon: 'tl-check-box', label: 'todo' },
      { text: 'Archive', icon: 'tl-archive', label: 'archived' },
      { text: 'Remove', icon: 'tl-delete', label: 'removed' },
    ],
  }),
  computed: {
    menuItemsFiltered() {
      return this.menuItems.filter(item => item.label != this.feedback.label)
    },
  },
  methods: {
    ...mapActions('dialog', ['prompt']),
    setLabel(label) {
      this.feedback.$update({ label })
      this.feedback.$apiSave()
    },
    reply() {
      this.prompt({
        title: 'Reply to feedback',
        text: `"${this.feedback.message}"`,
        inputInit: `${this.feedback.reply || ''}`,
        label: 'Feedback reply',
        placeholder: 'Write your reply to the user',
        ok: 'Done',
        onSave: reply => {
          this.feedback.$update({ reply })
          return this.feedback.$apiSave()
        },
      })
    },
  },
}
</script>
