<template>
  <v-card :loading="loading">
    <v-img height="200px" :src="require('./push-img.png')" />

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Notifications</v-list-item-title>
        <v-list-item-subtitle>Send messages to your clients</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      On the profile page, users can subscribe to receive push notifications or emails from different channels:
      <span ng-if="gym.nr_of_routes">new routes, </span>
      <span ng-if="gym.nr_of_boulders">new boulders, </span>
      news and competition news. Here you can create, schedule and edit notifications to be send to the subscribed
      users.
    </v-card-text>

    <v-card-actions>
      <v-btn text link color="primary" :to="{ name: 'gymComm.newNotification' }">New notification</v-btn>
      <v-spacer />
      <v-btn icon @click="fetchNotifications"><v-icon>tl-autorenew</v-icon></v-btn>
    </v-card-actions>

    <v-divider></v-divider>

    <div v-if="!notifications.length && !loading" class="ma-4">You have not send any notifications yet...</div>

    <v-list>
      <tl-n11s-admin-item
        v-for="notification in notificationsPaginated"
        :key="notification.id"
        :notification="notification"
      />
    </v-list>

    <tl-pagination-local :list="notifications" @list-paginated="notificationsPaginated = $event" :items-per-page="5" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Notification from '@/models/Notification'
import tlN11sAdminItem from './tl-n11s-admin-item'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlN11sAdminItem,
    tlPaginationLocal,
  },
  data: () => ({
    loading: true,
    editDialog: false,
    notificationsPaginated: [],
  }),
  computed: {
    ...mapState(['gym']),
    notifications() {
      return this.gym.notifications
        .slice()
        .sort((a, b) => b.processed_at - a.processed_at || b.process_at - a.process_at)
    },
  },
  created() {
    this.fetchNotifications()
  },
  methods: {
    fetchNotifications() {
      this.loading = true
      Notification.$apiAll({ params: { gym_id: this.gym.id } }).finally(() => (this.loading = false))
    },
  },
}
</script>
