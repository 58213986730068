<template>
  <v-container fluid class="py-0">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <tl-n11s-admin class="mb-4" />
        <tl-feedbacks-admin />
      </v-col>
      <v-col cols="12" md="4">
        <tl-reported-climbs-admin />
      </v-col>
    </v-row>
    <router-view />
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlN11sAdmin from './n11s/tl-n11s-admin'
import tlFeedbacksAdmin from './feedbacks/tl-feedbacks-admin'
import tlReportedClimbsAdmin from './reported/tl-reported-climbs-admin'

export default {
  mixins: [toolbarMixin],
  components: {
    tlN11sAdmin,
    tlFeedbacksAdmin,
    tlReportedClimbsAdmin,
  },
  data: () => ({
    tlToolbarTitle: 'Communications',
  }),
}
</script>
