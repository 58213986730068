<template>
  <div>
    <v-divider />
    <v-toolbar flat dense color="grey lighten-3">
      <v-toolbar-title class="subtitle-1">On wall '{{ this.climb.wall.name }}'</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="showInMap"><v-icon>tl-map</v-icon></v-btn>
          </template>
          <span>Show in map</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :loading="resolving" :disabled="disabled" @click="markResolved">
              <v-icon>tl-check</v-icon>
            </v-btn>
          </template>
          <span>Mark as resolved</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :loading="dismissing" :disabled="disabled" @click="dismiss">
              <v-icon>tl-delete</v-icon>
            </v-btn>
          </template>
          <span>Remove</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>
    <tl-list-climb :climb="climb" @click.native="showInMap" />
    <div v-for="opinion in opinions" :key="opinion.id">
      <v-divider inset />
      <v-list-item>
        <v-list-item-icon />
        <v-list-item-title
          v-text="opinion.report_message"
          class="warning--text"
          style="white-space: normal; user-select: text"
        />
        <v-list-item-subtitle class="text-right">{{ moment(opinion.date_reported).fromNow() }}</v-list-item-subtitle>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import Climb from '@/models/Climb'
import tlListClimb from '@/components/gym/climbs/list/tl-list-climb'

export default {
  components: {
    tlListClimb,
  },
  props: {
    climb: { type: Climb, default: () => {} },
  },
  data: () => ({
    resolving: false,
    dismissing: false,
  }),
  computed: {
    opinions() {
      return this.climb.opinions
    },
    disabled() {
      return this.resolving || this.dismissing
    },
  },
  methods: {
    markResolved() {
      this.resolving = true
      this.remove({ markResolved: true }).finally(() => (this.resolving = false))
    },
    dismiss() {
      this.dismissing = true
      this.remove().finally(() => (this.dismissing = false))
    },
    remove({ markResolved = false } = {}) {
      let dfds = this.opinions.map(opinion => {
        return opinion.$apiUpdate({
          report_removed: true,
          report_resolved: markResolved,
          date_report_removed: new Date(),
        })
      })
      return Promise.all(dfds)
    },
    showInMap() {
      this.$router.push({
        name: 'climbs.climb',
        params: {
          climbsType: `${this.climb.climb_type}s`,
          climbId: this.climb.id,
        },
      })
    },
  },
}
</script>
